import { get } from "lodash";
import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function MyLineChart({
  config = {},
  data = {},
  chartId,
  likertTranslateLabels,
  isLikert,
}) {
  const { maxCellValue, minCellValue, rows } = config || {};

  useLayoutEffect(() => {
    am4core.options.autoSetClassName = true;
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.data = data;
    chart.config = config;
    chart.interactionsEnabled = false;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.numberFormatter.numberFormat = "#.";
    valueAxis.maxPrecision = 0;
    valueAxis.min = minCellValue;
    valueAxis.max = maxCellValue;

    isLikert &&
      valueAxis.renderer.labels.template.adapter.add(
        "text",
        function (labelText) {
          const value = parseInt(labelText);
          return likertTranslateLabels[value - 1] || labelText;
        },
      );

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "keyword";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    // Configure axis label
    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.wrap = true;
    label.maxWidth = 150;
    valueAxis.numberFormatter.numberFormat = "#.";
    categoryAxis.numberFormatter.numberFormat = "#.";
    label.tooltipText = "{keyword}";

    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });
    categoryAxis.renderer.labels.template.events.on("over", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "soft");
    });

    categoryAxis.renderer.labels.template.events.on("out", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "none");
    });
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    valueAxis.tooltip.disabled = true;

    am4core.addLicense("ch-custom-attribution");

    // Create series
    for (const row of config?.rows || []) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = row;
      series.dataFields.categoryX = "keyword";
      series.name = row
        .replace(/#|_/g, "")
        .replace("  ", " " + get(data, [0, row]) + " ");
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color("#F27329");

      var interfaceColors = new am4core.InterfaceColorSet();

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = interfaceColors.getFor("background");
      bullet.circle.strokeWidth = 2;
      bullet.circle.fill = "#B92626";
    }

    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={chartId} style={{ width: "100%", height: "500px" }}></div>;
}

export default MyLineChart;
