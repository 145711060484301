import React, { useLayoutEffect } from "react";
import { StyledRadarChart } from "./styled";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { get } from "lodash";

function MyRadarChart({
  config = {},
  data = {},
  chartId,
  likertTranslateLabels,
  isLikert,
}) {
  const { maxCellValue, minCellValue } = config || {};

  useLayoutEffect(() => {
    am4core.options.autoSetClassName = true;
    /* am4core.useTheme(am4themes_animated) */

    const chart = am4core.create(chartId, am4charts.RadarChart);
    chart.data = data;
    chart.config = config;
    chart.interactionsEnabled = false;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
    valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
    valueAxis.min = minCellValue;

    valueAxis.numberFormatter.numberFormat = "#.";
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.max = maxCellValue;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.cursorTooltipEnabled = true;

    isLikert &&
      valueAxis.renderer.labels.template.adapter.add(
        "text",
        function (labelText) {
          const value = parseInt(labelText);
          return likertTranslateLabels[value - 1] || labelText;
        },
      );

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "keyword";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    // Configure axis label
    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.wrap = true;
    label.maxWidth = 120;
    label.tooltipText = "{keyword}";

    am4core.addLicense("ch-custom-attribution");

    // Create series
    for (const row of config?.rows || []) {
      const series = chart.series.push(new am4charts.RadarSeries());
      series.dataFields.valueY = row;
      series.dataFields.categoryX = "keyword";
      series.name = row
        .replace(/#|_/g, "")
        .replace("  ", " " + get(data, [0, row]) + " ");
      series.strokeWidth = 3;
      series.fillOpacity = 0.2;
      series.tooltipText = "{valueY}";
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.label.minWidth = 40;
      series.tooltip.label.minHeight = 40;
      series.tooltip.label.textAlign = "middle";
      series.stroke = am4core.color("#F27329");
      series.fill = am4core.color("#F27329");
    }
    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.RadarCursor();
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <StyledRadarChart
      contentValign={true}
      fontSize="11px"
      id={chartId}
      style={{ width: "600px", height: "500px" }}
    />
  );
}
export default MyRadarChart;
