import React, { useCallback, useEffect } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons/lib/icons";
import { get } from "lodash";
import { StyledSelect, StyledSelectButton, StyledSelectValue } from "./styled";

function Select({ row, moment, values, onChange, config }) {
  const { maxCellValue, minCellValue, rows } = config || {};

  const valueCell = get(values, [row], {});
  const value = get(valueCell, [moment]);
  const isDisabled = !get(valueCell, ["keyword"]);

  const isLikert = maxCellValue === 5;

  const likertTranslateValues = [
    "Per Niente",
    "Poco",
    "Abbastanza",
    "Molto",
    "Moltissimo",
  ];

  const renderValue = isLikert
    ? likertTranslateValues[value - 1] ||
      likertTranslateValues[minCellValue - 1]
    : value || minCellValue;

  // initial values
  useEffect(() => {
    if (!isDisabled && !value) {
      onChange({
        ...values,
        [row]: {
          ...valueCell,
          [moment]: minCellValue,
        },
      });
    }
  }, [
    onChange,
    moment,
    values,
    minCellValue,
    row,
    valueCell,
    isDisabled,
    rows,
    value,
  ]);

  const increasePoints = useCallback(() => {
    onChange({
      ...values,
      [row]: {
        ...valueCell,
        [moment]: Math.min(value + 1, parseInt(maxCellValue)),
      },
    });
  }, [onChange, values, value, maxCellValue, moment, row, valueCell]);

  const decreasePoints = useCallback(() => {
    onChange({
      ...values,
      [row]: {
        ...valueCell,
        [moment]: Math.max(value - 1, parseInt(minCellValue)),
      },
    });
  }, [onChange, values, value, minCellValue, moment, row, valueCell]);

  return (
    <StyledSelect
      disabled={isDisabled}
      title={isDisabled && "Scegli una keyword"}
      isLikert={isLikert}
    >
      <StyledSelectButton
        disabled={isDisabled}
        shape="circle"
        size="small"
        icon={<MinusOutlined />}
        onClick={decreasePoints}
      />
      <StyledSelectValue>{renderValue}</StyledSelectValue>
      <StyledSelectButton
        disabled={isDisabled}
        size="small"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={increasePoints}
      />
    </StyledSelect>
  );
}

export default React.memo(Select);
