import React, { useCallback, useState } from "react";
import BarChart from "./BarChart";
import MyLineChart from "./LineChart";
import RadarChart from "./RadarChart";
import { MainChartsContainer, StyledSwitchArea } from "./styled";
import SwitchButton from "./SwitchButton/SwitchButton";

export const colorsChart = [
  "#F27329",
  "#B92626",
  "#FF5E00",
  "#EAB925",
  "#8C082C",
  "#F28D35",
  "#626262",
];

const likertTranslateLabels = [
  "Per Niente",
  "Poco",
  "Abbastanza",
  "Molto",
  "Moltissimo",
];

const ChartsContainer = ({ config = {}, value = {}, chartId }) => {
  const [activeChart, setActiveChart] = useState("bar");
  const changeChart = useCallback((mode) => {
    setActiveChart(mode);
  }, []);

  const { maxCellValue } = config || {};

  const isLikert = maxCellValue === 5;

  const data = Object.values(value);
  /* React.useEffect(() => {
    console.log('mount', chartId)
    return () => {
      console.log('unmount', chartId)
    }
  }, []) */
  return (
    <MainChartsContainer>
      <StyledSwitchArea>
        <SwitchButton
          active={activeChart === "bar"}
          name="Barre"
          onClick={() => changeChart("bar")}
        />
        <SwitchButton
          active={activeChart === "linea"}
          name="Linee"
          onClick={() => changeChart("linea")}
        />
        {Object.keys(data).length >= 3 ? (
          <SwitchButton
            active={activeChart === "radar"}
            name="radar"
            onClick={() => changeChart("radar")}
          />
        ) : null}
      </StyledSwitchArea>

      {activeChart === "radar" ? (
        <RadarChart
          data={data}
          config={config}
          chartId={chartId + `radar`}
          likertTranslateLabels={likertTranslateLabels}
          isLikert={isLikert}
        />
      ) : activeChart === "bar" ? (
        <BarChart
          data={data}
          config={config}
          chartId={chartId + `bar`}
          likertTranslateLabels={likertTranslateLabels}
          isLikert={isLikert}
        />
      ) : (
        <MyLineChart
          data={data}
          config={config}
          chartId={chartId + `line`}
          likertTranslateLabels={likertTranslateLabels}
          isLikert={isLikert}
        />
      )}
    </MainChartsContainer>
  );
};

export default ChartsContainer;
